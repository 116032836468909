<template>
  <b-card :title="$t('finance_operation')">
    <b-row>
      <b-col sm="12"  class="text-right">
        <router-link :to="{ name: 'operation.finance.create' }" class="btn btn-sm btn-success"><fa icon="plus" /></router-link>
      </b-col>
      <b-table
        responsive
        striped
        hover
        show-empty
        sort-icon-left
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0">
        <template v-slot:cell(documentStatus)="row">
          <span v-if="row.item.documentStatus === 'new'" class="badge badge-info">{{ $t('new') }}</span>
          <span v-else-if="row.item.documentStatus === 'waiting'" class="badge badge-warning">{{ $t('waiting') }}</span>
          <span v-else class="badge badge-danger">{{ $t('conducted') }}</span>
        </template>
        <template v-slot:cell(sum)="row">
          <span class="text-danger">{{ row.item.sum | currency }}</span>
        </template>
        <template v-slot:cell(sumPaid)="row">
          <span class="text-danger">{{ row.item.sumPaid | currency }}</span>
        </template>
        <template v-slot:cell(paymentStatus)="row">
          <span v-if="row.item.paymentStatus === 'paid'" class="badge badge-success">{{ $t('paid') }}</span>
          <span v-else class="badge badge-danger">{{ $t('unpaid') }}</span>
        </template>
        <template v-slot:cell(financeOperationType)="row">
          <span v-if="row.item.financeOperationType === 'input'" class="badge badge-info">{{ $t('arrival') }}</span>
          <span v-else-if="row.item.financeOperationType === 'output'" class="badge badge-warning">{{ $t('spending') }}</span>
        </template>
        <template v-slot:cell(counterpartyFinalDebt)="row">
          <span class="text-danger">{{ row.item.counterpartyFinalDebt | currency }}</span>
        </template>
        <template v-slot:cell(ourFinalDebt)="row">
          <span class="text-danger">{{ row.item.ourFinalDebt | currency }}</span>
        </template>
        <template v-slot:cell(balance)="row">
          <span class="text-danger">{{ (row.item.balance < 0) ? 0 : row.item.balance | currency }}</span>
        </template>
        <template v-slot:cell(actions)="row">
          <b-button-group>
            <router-link :to="{ name: 'operation.finance.show', params: { 'id': row.item.id }}" class="btn btn-sm btn-primary rounded-pill mr-1"><fa icon="eye" /></router-link>
            <router-link :to="{ name: 'operation.finance.edit', params: { 'id': row.item.id, 'copy': false } }" class="btn btn-sm btn-warning rounded-pill mr-1"><fa icon="pencil-alt" /></router-link>
            <router-link :to="{ name: 'operation.finance.edit', params: { 'id': row.item.id, 'copy': true } }" class="btn btn-sm btn-success rounded-pill"><fa icon="plus" /></router-link>
          </b-button-group>
        </template>
      </b-table>
    </b-row>
  </b-card>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    items: null,
    totalRows: 1,
    currentPage: 1,
    perPage: 10,
    totalItems: 0,
    urlParams: {},
    fields: [
      {
        key: 'date',
        label: 'Дата'
      },
      {
        key: 'number',
        label: 'Номер',
        class: 'text-center'
      },
      {
        key: 'operationType',
        label: 'Тип операції',
        typeSort: 'select',
        class: 'text-center'
      },
      {
        key: 'documentName',
        label: 'Назва док.'
      },
      {
        key: 'documentStatus',
        label: 'Статус док.',
        class: 'text-center'
      },
      {
        key: 'company',
        label: 'Компанія'
      },
      {
        key: 'user',
        label: 'ПІБ'
      },
      {
        key: 'sum',
        label: 'Сума',
        class: 'text-center'
      },
      {
        key: 'sumPaid',
        label: 'Сума оплачено',
        class: 'text-center'
      },
      {
        key: 'paymentStatus',
        label: 'Стутус оплати',
        class: 'text-center'
      },
      {
        key: 'financeOperationType',
        label: 'Тип фін.опер.',
        class: 'text-center'
      },
      {
        key: 'cashRegisters',
        label: 'Каса',
        class: 'text-center'
      },
      {
        key: 'counterpartyFinalDebt',
        label: 'Кінц.борг контр.',
        class: 'text-center'
      },
      {
        key: 'ourFinalDebt',
        label: 'Наш кінц.борг',
        class: 'text-center'
      },
      {
        key: 'balance',
        label: 'Сальдо',
        class: 'text-center'
      },
      {
        key: 'actions',
        label: 'Дії',
        class: 'text-center'
      }
    ]
  }),
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'operations').then(resp => {
        if (resp.data) {
          this.items = resp.data.data
        }
      })
    },
    buildQuery () {
      return Object.entries(this.urlParams).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.fetchData()
      }
    },
    perPage: {
      handler: function (value) {
        this.fetchData()
      }
    }
  }
}
</script>
